import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from '@/assets/icons/custom/dashboard-icon.svg';
import { ReactComponent as Question } from '@/assets/icons/custom/question.svg';
import { ReactComponent as AddressBook } from '@/assets/icons/LineAwesome/address-book.svg';
import { ReactComponent as Analytics } from '@/assets/icons/LineAwesome/chart-bar-solid.svg';
import { ReactComponent as CogSolid } from '@/assets/icons/LineAwesome/cog-solid.svg';
import { ReactComponent as Cubes } from '@/assets/icons/LineAwesome/cubes-solid.svg';
import { ReactComponent as InvoiceDollar } from '@/assets/icons/LineAwesome/file-invoice-dollar-solid.svg';
import { ReactComponent as LuggageCartSolid } from '@/assets/icons/LineAwesome/luggage-cart-solid.svg';
import { ReactComponent as MapMarkedAltSolid } from '@/assets/icons/LineAwesome/map-marked-alt-solid.svg';
import { ReactComponent as Paste } from '@/assets/icons/LineAwesome/paste-solid.svg';
import { ReactComponent as ShipSolid } from '@/assets/icons/LineAwesome/ship-solid.svg';
import { SIMULATION_CUSTOMER_USER_PATHS } from '@/routes/simulation';
import { SidebarMenu, MenuItem } from '@/shared/components/Sidebar/SidebarMenu/SidebarMenu';
import { AppState } from '@/shared/redux/AppState';
import { useLogDirectAccessSidebarClick } from '@/shared/utils/hooks/ActivityLogging/useLogDirectAccessSidebarClick';
import { useAuth } from '@/shared/utils/hooks/useAuth';
import { ACTIVE_TAB_LOCAL_STORAGE_KEY, QUOTE_TABS } from '@/utils/constants/quote';

const getActiveQuoteTab = () => localStorage.getItem(ACTIVE_TAB_LOCAL_STORAGE_KEY)
  || QUOTE_TABS.quote;

export const SidebarMenuContainer = () => {
  const { 'T_FO_SG_FE_in-transit-product-list_20230918': isInTransitProductListFlagOn } = useFlags();
  const { 'P_FO_FDN_ALL_access-analytics-dashboard_20231001': canAccessAnalyticsDashboard } = useFlags();
  const { t } = useTranslation(['translation']);
  const { isStaffUser } = useAuth();
  const { pathname } = useLocation();

  const teamId = useSelector((state: AppState) => state.user.teamId);
  const isStaff = isStaffUser();

  const [pushUIEvent] = useLogDirectAccessSidebarClick(teamId);

  /**
   * General the Quote URL depending on the last active Quote tab
   */
  const generateQuoteUrl = () => {
    if (!isStaff) {
      // If non-internal mode, then use the value from local storage
      // to determine which tab should be the link for the quote menu item
      switch (getActiveQuoteTab()) {
        case 'savedQuote':
          return SIMULATION_CUSTOMER_USER_PATHS.savedQuote;

        case 'quoteRequests':
          return SIMULATION_CUSTOMER_USER_PATHS.requestQuote;

        default:
          return SIMULATION_CUSTOMER_USER_PATHS.default;
      }
    }

    // Don't require persistence for internal mode
    return `/team/${teamId}/quote`;
  };

  /**
   * Handler that dispatches direct access UI event
   * when user clicks on menu items
   */
  const handleOnClick = useCallback((link) => {
    if (link !== 'quote') {
      pushUIEvent(link);
    }

    if (getActiveQuoteTab() === QUOTE_TABS.quote) {
      pushUIEvent('quote-tab');
    }
  }, [pushUIEvent]);

  const menuItems: MenuItem[][] = isInTransitProductListFlagOn
    ? [
      [ // Menu items above the divider
        {
          type: 'link',
          title: t('translation:navigation.dashboard'),
          to: '/dashboard',
          disabled: isStaff,
          icon: <DashboardIcon style={{ height: `${14 / 16}rem` }} />,
          isActive: () => (isStaff ? false : pathname.includes('/dashboard')),
          onClick: () => handleOnClick('dashboard'),
        },
        {
          type: 'link',
          title: t('translation:navigation.shipments'),
          to: {
            pathname: '/shipments',
            state: { from: 'sidebar' },
          },
          disabled: isStaff,
          icon: <ShipSolid />,
          isActive: () => (isStaff ? false : pathname.includes('/shipment')),
          onClick: () => handleOnClick('shipment'),
        },
        {
          type: 'link',
          title: t('translation:navigation.inTransitProductItems'),
          to: '/in-transit-product-items',
          disabled: isStaff,
          icon: <Cubes />,
          isActive: () => (isStaff ? false : pathname.includes('/in-transit-product-items')),
        },
        canAccessAnalyticsDashboard && {
          type: 'link',
          title: t('translation:navigation.analytics'),
          to: '/analytics',
          disabled: isStaff,
          icon: <Analytics />,
          isActive: () => (isStaff ? false : pathname.includes('/analytics')),
        },
        {
          type: 'link',
          title: t('translation:navigation.quote'),
          to: generateQuoteUrl(),
          disabled: isStaff,
          icon: <Paste />,
          isActive: () => pathname.includes('/quote') || pathname.includes('/shipment-request'),
          onClick: () => handleOnClick('quote'),
        },
        {
          type: 'link',
          title: t('translation:navigation.invoices'),
          disabled: isStaff,
          to: '/invoices',
          icon: <InvoiceDollar />,
        },
      ], [ // Menu items below the divider
        {
          type: 'link',
          title: t('translation:navigation.productManagement'),
          to: '/products',
          disabled: isStaff,
          icon: <LuggageCartSolid />,
        },
        {
          type: 'link',
          title: t('translation:navigation.customerManagement'),
          to: '/partners',
          disabled: isStaff,
          icon: <AddressBook />,
        },
        {
          type: 'link',
          title: t('translation:navigation.locationManagement'),
          to: '/places',
          disabled: isStaff,
          icon: <MapMarkedAltSolid />,
        },
        {
          type: 'link',
          title: t('translation:navigation.setting'),
          to: '/settings',
          disabled: isStaff,
          icon: <CogSolid />,
        },
        {
          type: 'button',
          title: t('translation:navigation.support'),
          disabled: isStaff,
          icon: <Question />,
        },
      ],
    ]
    : [
      [ // Menu items above the divider
        {
          type: 'link',
          title: t('translation:navigation.dashboard'),
          to: '/dashboard',
          disabled: isStaff,
          icon: <DashboardIcon style={{ height: `${14 / 16}rem` }} />,
          isActive: () => (isStaff ? false : pathname.includes('/dashboard')),
          onClick: () => handleOnClick('dashboard'),
        },
        {
          type: 'link',
          title: t('translation:navigation.shipments'),
          to: {
            pathname: '/shipments',
            state: { from: 'sidebar' },
          },
          disabled: isStaff,
          icon: <ShipSolid />,
          isActive: () => (isStaff ? false : pathname.includes('/shipment')),
          onClick: () => handleOnClick('shipment'),
        },
        canAccessAnalyticsDashboard && {
          type: 'link',
          title: t('translation:navigation.analytics'),
          to: '/analytics',
          disabled: isStaff,
          icon: <Analytics />,
          isActive: () => (isStaff ? false : pathname.includes('/analytics')),
        },
        {
          type: 'link',
          title: t('translation:navigation.quote'),
          to: generateQuoteUrl(),
          disabled: isStaff,
          icon: <Paste />,
          isActive: () => pathname.includes('/quote') || pathname.includes('/shipment-request'),
          onClick: () => handleOnClick('quote'),
        },
        {
          type: 'link',
          title: t('translation:navigation.invoices'),
          disabled: isStaff,
          to: '/invoices',
          icon: <InvoiceDollar />,
        },
      ], [ // Menu items below the divider
        {
          type: 'link',
          title: t('translation:navigation.productManagement'),
          to: '/products',
          disabled: isStaff,
          icon: <LuggageCartSolid />,
        },
        {
          type: 'link',
          title: t('translation:navigation.customerManagement'),
          to: '/partners',
          disabled: isStaff,
          icon: <AddressBook />,
        },
        {
          type: 'link',
          title: t('translation:navigation.locationManagement'),
          to: '/places',
          disabled: isStaff,
          icon: <MapMarkedAltSolid />,
        },
        {
          type: 'link',
          title: t('translation:navigation.setting'),
          to: '/settings',
          disabled: isStaff,
          icon: <CogSolid />,
        },
        {
          type: 'button',
          title: t('translation:navigation.support'),
          disabled: isStaff,
          icon: <Question />,
        },
      ],
    ];

  return (
    <SidebarMenu menuItems={menuItems.map((subList) => subList.filter(Boolean))} />
  );
};
