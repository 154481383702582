import { AnyAction } from '@reduxjs/toolkit';

import { addAddress } from './actions';
import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initialAddresses: Record<string, string> = {};

const addresses = (state = initialAddresses, action: AnyAction) => {
  switch (action.type) {
    case types.ADDRESSES_ADD: {
      const cloned = { ...state };
      const { key, address } = action.payload as ReturnType<typeof addAddress>['payload'];
      cloned[key] = address;
      return cloned;
    }

    default:
      return state;
  }
};

export default addresses;
