import { INTERNAL_MODE_ROUTE_TYPES } from '../../utils/constants/quote';
import { SIMULATION_MODES } from '../../utils/constants/simulations';

export const SIMULATION_CUSTOMER_USER_PATHS = {
  savedQuote: `/${SIMULATION_MODES.quote}/saved`,
  requestQuote: `/${SIMULATION_MODES.quote}/requests`,
  requestQuoteDetails: `/${SIMULATION_MODES.quote}/requests/:requestId`,
  requestQuoteForm: `/${SIMULATION_MODES.quote}/requests/create`,
  requestQuoteFormSuccess: `/${SIMULATION_MODES.quote}/requests/create/success`,
  lane: `/${SIMULATION_MODES.quote}/:laneId`,
  default: `/${SIMULATION_MODES.quote}`,
  confirm: `/${SIMULATION_MODES.confirmShipment}/:shipmentId`,
} as const;

export const SIMULATION_STAFF_PATHS = {
  lane: `/team/:teamId/${SIMULATION_MODES.quote}/:laneId`,
  default: `/team/:teamId/${SIMULATION_MODES.quote}`,
} as const;

export const PRE_SHIPMENT_PATHS = {
  savedQuote: `/team/:teamId/${SIMULATION_MODES.shipmentRequest}/saved`,
  lane: `/team/:teamId/${SIMULATION_MODES.shipmentRequest}/:laneId`,
  default: `/team/:teamId/${SIMULATION_MODES.shipmentRequest}`,
} as const;

export const SIMULATION_CUSTOMER_USER_ROUTES = [
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.savedQuote,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.requestQuote,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.requestQuoteForm,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.requestQuoteFormSuccess,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.requestQuoteDetails,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.lane,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.default,
    exact: true,
  },
  {
    path: SIMULATION_CUSTOMER_USER_PATHS.confirm,
    exact: true,
  },
] as const;

export const SIMULATION_STAFF_ROUTES = [
  {
    path: SIMULATION_STAFF_PATHS.lane,
    exact: true,
  },
  {
    path: SIMULATION_STAFF_PATHS.default,
    exact: true,
  },
] as const;

export const PRE_SHIPMENT_STAFF_ROUTES = [
  {
    path: PRE_SHIPMENT_PATHS.savedQuote,
    exact: true,
  },
  {
    path: PRE_SHIPMENT_PATHS.lane,
    exact: true,
  },
  {
    path: PRE_SHIPMENT_PATHS.default,
    exact: true,
  },
] as const;

export const generateSimulationPath = (
  type: string | null,
  teamId: string,
  laneId: null,
) => {
  switch (type) {
    case INTERNAL_MODE_ROUTE_TYPES.quoteStaff:
      if (laneId) {
        return `/team/${teamId}/${SIMULATION_MODES.quote}/${laneId}`;
      }

      return `/team/${teamId}/${SIMULATION_MODES.quote}`;

    case INTERNAL_MODE_ROUTE_TYPES.shipmentRequest:
      if (laneId) {
        return `/team/${teamId}/${SIMULATION_MODES.shipmentRequest}/${laneId}`;
      }

      return `/team/${teamId}/${SIMULATION_MODES.shipmentRequest}`;

    default:
      if (laneId) {
        return `/${SIMULATION_MODES.quote}/${laneId}`;
      }

      return `/${SIMULATION_MODES.quote}`;
  }
};

export const generateSavedQuotePath = (type: string | null, teamId: string) => {
  switch (type) {
    case INTERNAL_MODE_ROUTE_TYPES.quoteStaff:
      return `/team/${teamId}/${SIMULATION_MODES.quote}/saved`;

    case INTERNAL_MODE_ROUTE_TYPES.shipmentRequest:
      return `/team/${teamId}/${SIMULATION_MODES.shipmentRequest}/saved`;

    default:
      return `/${SIMULATION_MODES.quote}/saved`;
  }
};
