import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { allowUIEvents } from '@/shared/redux/middleware/allowUIEvents';
import { sharedReducers, devTools } from '@/shared/redux/store';

import places from './places/reducers';
import addresses from '../../redux/addresses/reducers';
import { invoices } from '../../redux/invoices/reducers';
import quoteForm from '../../redux/quoteRequestForm/reducers';
import selectedSimulation from '../../redux/selectedSimulation/reducers';
import simulation from '../../redux/simulation/reducers';

export const store = configureStore({
  reducer: combineReducers({
    ...sharedReducers,
    addresses,
    invoices,
    places,
    quoteForm,
    selectedSimulation,
    simulation,
  }),
  middleware: (getDefault) => getDefault({ serializableCheck: false }).concat([
    allowUIEvents,
  ]),
  devTools,
});

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
