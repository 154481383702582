// cascades

import { CARGO_TYPE } from '../../shared/utils/constants/shipments';
import { getLocalStorageObject } from '../../shared/utils/utils';
import { PACKAGING_STATUS_TYPES } from '../../utils/constants/products';
import * as CONSTANTS from '../../utils/constants/simulations';
import { parseSimulationVars } from '../../utils/helpers/simulationHelpers';
import { cascadeForRecommendedTruck, cascadeOnTradeDirection } from './cascades';
import * as types from './types';

/**
 * Generates the initial import/export data, whether
 * we use static default data, or data from local
 * storage.
 * @param {string} direction
 */
const initialImportExport = (direction) => ({
  transportationMode: getLocalStorageObject(
    CONSTANTS.STORAGE_TRANSPORTATION_MODE,
    CONSTANTS.STORAGE_DEFAULTS.STORAGE_TRANSPORTATION_MODE,
  )?.[direction],
  incoterm: getLocalStorageObject(
    CONSTANTS.STORAGE_INCOTERMS,
    CONSTANTS.STORAGE_DEFAULTS.STORAGE_INCOTERMS,
  )?.[direction],
  POL: CONSTANTS.STORAGE_DEFAULTS.STORAGE_ROUTE?.[direction]?.POL,
  POD: CONSTANTS.STORAGE_DEFAULTS.STORAGE_ROUTE?.[direction]?.POD,
});

const defaultPlace = {
  address: null,
  type: null,
  quantity: 1,
};

const initialPlace = {
  disable: false,
  reservation: true,
  places: [defaultPlace],
};

const defaultManualInput = {
  packagingStatus: null,
  totalVolume: null,
  totalWeight: null,
};

const defaultSliderValues = {
  // * Minimum value is set as default as per the spec(https://shippio.kibe.la/notes/13499#the-slider-ui)
  LCL: {
    totalVolume: 1,
    totalWeight: 100,
  },
  AIR: {
    totalVolume: 0,
    totalWeight: 1,
  },
};

// eslint-disable-next-line dot-notation
const baseContainer = { containerType: '', containerCount: 1 };
const baseProduct = {
  height: null,
  length: null,
  name: '',
  packagingType: null,
  productId: null,
  quantity: null,
  weight: null,
  width: null,
};

const initialSimulation = {
  simulationMode: null, // for different modes
  shipmentId: null, // for accept import
  missingValues: CONSTANTS.MISSING_VALUES,
  tradeDirection: localStorage.getItem(CONSTANTS.STORAGE_TRADE_DIRECTION)
    || CONSTANTS.STORAGE_DEFAULTS.STORAGE_TRADE_DIRECTION,
  import: {
    ...initialImportExport('import'),
  },
  export: {
    ...initialImportExport('export'),
  },
  cargoType: { // refactor cargoType to just cargo?
    activeCargoType: localStorage.getItem(CONSTANTS.STORAGE_CARGO_TYPE)
      || CONSTANTS.STORAGE_DEFAULTS.STORAGE_CARGO_TYPE,
    isManualInput: false,
    isCargoDetailsSimulationOpen: {
      LCL: false,
      AIR: false,
    },
    FCL: [
      { ...baseContainer },
    ],
    LCL: [
      { ...baseProduct },
    ],
    AIR: [
      { ...baseProduct },
    ],
    manualInput: {
      ...defaultManualInput,
    },
    sliderValues: {
      ...defaultSliderValues,
    },
  },
  places: {
    domestic: {
      drayage: initialPlace,
      trucking: initialPlace,
    },
    overseas: {
      drayage: initialPlace,
      trucking: initialPlace,
    },
  },
  additionalRequests: {
    customs: true,
    insurance: true,
    packing: true,
  },
  vehicleManuallyChanged: false,
  recommendedTruck: null,
};

const simulation = (state = initialSimulation, action) => {
  switch (action.type) {
    case types.CONTAINER_DELETE: {
      const { cargoType, index } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [cargoType]: state.cargoType[cargoType].filter(
            (item, i) => i !== index,
          ),
        },
      };
    }

    case types.CONTAINER_ADD: {
      const { cargoType } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [cargoType]: [
            ...state.cargoType[cargoType],
            { ...baseContainer },
          ],
        },
      };
    }

    case types.CONTAINER_COUNT_EDIT: {
      const { cargoType, containerCount, index } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [cargoType]: state.cargoType[cargoType].map((item, i) => {
            if (i !== index) {
              return { ...item };
            }

            return {
              ...item,
              containerCount,
            };
          }),
        },
      };
    }

    case types.CONTAINER_TYPE_EDIT: {
      const { cargoType, containerType, index } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [cargoType]: state.cargoType[cargoType].map((item, i) => {
            if (i !== index) {
              return { ...item };
            }
            return {
              ...item,
              containerType,
            };
          }),
        },
      };
    }

    case types.CONTAINERS_EDIT: {
      const { cargoType, containers } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [cargoType]: containers,
        },
      };
    }

    case types.CARGO_TYPE_EDIT: {
      const { cargoType } = action.payload;

      // Save to the local storage
      localStorage.setItem(CONSTANTS.STORAGE_CARGO_TYPE, cargoType);

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          activeCargoType: cargoType,
        },
      };
    }

    case types.TRADE_DIRECTION_EDIT: {
      const { direction } = action.payload;

      // Save to the local storage
      localStorage.setItem(CONSTANTS.STORAGE_TRADE_DIRECTION, direction);

      const newState = {
        ...state,
        tradeDirection: direction,
      };

      return cascadeOnTradeDirection(newState);
    }

    case types.TRANSPORTATION_MODE_EDIT: {
      const { direction, type } = action.payload;

      // Save to the local storage
      const localStorageTransportationMode = getLocalStorageObject(
        CONSTANTS.STORAGE_TRANSPORTATION_MODE,
        CONSTANTS.STORAGE_DEFAULTS.STORAGE_TRANSPORTATION_MODE,
      );

      localStorageTransportationMode[direction] = type;

      localStorage.setItem(
        CONSTANTS.STORAGE_TRANSPORTATION_MODE,
        JSON.stringify(localStorageTransportationMode),
      );

      return {
        ...state,
        [direction]: {
          ...state[direction],
          transportationMode: type,
        },
      };
    }

    case types.INCOTERM_EDIT: {
      const { direction, incoterm } = action.payload;

      // Save to the local storage
      const localStorageIncoterms = getLocalStorageObject(
        CONSTANTS.STORAGE_INCOTERMS,
        CONSTANTS.STORAGE_DEFAULTS.STORAGE_INCOTERMS,
      );

      localStorageIncoterms[direction] = incoterm;

      localStorage.setItem(CONSTANTS.STORAGE_INCOTERMS, JSON.stringify(localStorageIncoterms));

      return {
        ...state,
        [direction]: {
          ...state[direction],
          incoterm,
        },
      };
    }

    case types.POL_EDIT: {
      const { direction, POL } = action.payload;

      return {
        ...state,
        [direction]: {
          ...state[direction],
          POL,
        },
      };
    }

    case types.POD_EDIT: {
      const { direction, POD } = action.payload;

      return {
        ...state,
        [direction]: {
          ...state[direction],
          POD,
        },
      };
    }

    case types.PLACE_ADD: {
      const { location, transportType } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              places: [
                ...state.places[location][transportType].places,
                defaultPlace,
              ],
            },
          },
        },
      };
    }

    case types.PLACE_EDIT_VEHICLE_TYPE: {
      const {
        location, transportType, index, vehicleType, manuallyChanged,
      } = action.payload;
      return {
        ...state,
        vehicleManuallyChanged: manuallyChanged,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              places: state.places[location][transportType].places.map((item, i) => {
                if (i !== index) {
                  return item;
                }

                return {
                  ...item,
                  type: vehicleType,
                };
              }),
            },
          },
        },
      };
    }

    case types.PLACE_EDIT_QUANTITY: {
      const {
        location, transportType, index, quantity,
      } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              places: state.places[location][transportType].places.map((item, i) => {
                if (i !== index) {
                  return item;
                }

                return {
                  ...item,
                  quantity,
                };
              }),
            },
          },
        },
      };
    }

    case types.PLACE_EDIT_ADDRESS: {
      const {
        location, transportType, index, address,
      } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              places: state.places[location][transportType].places.map((item, i) => {
                if (i !== index) {
                  return item;
                }

                return {
                  ...item,
                  address,
                };
              }),
            },
          },
        },
      };
    }

    case types.PLACE_DELETE: {
      const { location, transportType, index } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              places: [
                ...state.places[location][transportType].places.slice(0, index),
                ...state.places[location][transportType].places.slice(index + 1),
              ],
            },
          },
        },
      };
    }

    case types.PLACE_RESERVATION_EDIT: {
      const { location, transportType, reservation } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              reservation,
            },
          },
        },
      };
    }

    case types.PLACE_DISABLE_EDIT: {
      const { location, transportType, disable } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: {
              ...state.places[location][transportType],
              disable,
              // only toggle reservation if simulation mode is not confirm
              // shipment, we need the reservation value to stay as determined
              // by the temporary shipment
              reservation: state.simulationMode === 'confirm-shipment'
                ? state.places[location][transportType].reservation
                : !disable,
            },
          },
        },
      };
    }

    case types.PLACES_EDIT: {
      const { location, transportType, places } = action.payload;
      return {
        ...state,
        places: {
          ...state.places,
          [location]: {
            ...state.places[location],
            [transportType]: places,
          },
        },
      };
    }

    case types.ADDITIONAL_REQUEST_EDIT: {
      const { request, value } = action.payload;
      return {
        ...state,
        additionalRequests: {
          ...state.additionalRequests,
          [request]: value,
        },
      };
    }

    case types.PRODUCT_ADD: {
      const { productCargo } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: [
            ...state.cargoType[productCargo],
            { ...baseProduct },
          ],
        },
      };
    }

    case types.PRODUCT_EDIT: {
      const { index, updatedProduct, productCargo } = action.payload;

      const newProductList = state.cargoType[productCargo].map((cargo, i) => {
        // Check the given index and return the as-is value for not edit target
        if (i !== index) return cargo;

        const { id, ...rest } = updatedProduct;
        return {
          productId: id,
          ...rest,
          quantity: updatedProduct.quantity || 1,
        };
      });

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: newProductList,
        },
      };
    }

    case types.PRODUCT_DELETE: {
      const { index, productCargo } = action.payload;

      const newProductList = state.cargoType[productCargo].filter(
        (product, i) => index !== i,
      );

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: newProductList,
        },
      };
    }

    case types.PRODUCT_QUANTITY_EDIT: {
      const { index, newQuantity, productCargo } = action.payload;

      const newProductList = state.cargoType[productCargo].map((cargo, i) => {
        if (i !== index) return cargo;

        return {
          ...cargo,
          quantity: newQuantity,
        };
      });

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: newProductList,
        },
      };
    }

    case types.REGISTERED_PRODUCT_DESELECT: {
      const { index, productCargo } = action.payload;

      const newProductList = state.cargoType[productCargo].map((product, i) => {
        // Return the object as is when index doesn't match
        if (i !== index) return product;

        // Update only productId to null
        return {
          ...product,
          productId: null,
        };
      });

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: newProductList,
        },
      };
    }

    case types.PRODUCTS_EDIT: {
      const { productCargo, products } = action.payload;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          [productCargo]: products,
        },
      };
    }

    case types.MANUAL_INPUT_EDIT: {
      const { isManualInput } = action.payload;
      const packagingStatus = isManualInput ? PACKAGING_STATUS_TYPES.before_packing : null;

      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          isManualInput,
          manualInput: {
            ...state.cargoType.manualInput,
            packagingStatus,
          },
        },
      };
    }

    case types.TOTAL_VOLUME_EDIT: {
      const { totalVolume } = action.payload;
      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          manualInput: {
            ...state.cargoType.manualInput,
            totalVolume,
          },
        },
      };
      return cascadeForRecommendedTruck(newState, null);
    }

    case types.TOTAL_WEIGHT_EDIT: {
      const { totalWeight } = action.payload;
      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          manualInput: {
            ...state.cargoType.manualInput,
            totalWeight,
          },
        },
      };
      return cascadeForRecommendedTruck(newState, null);
    }

    case types.PACKAGING_STATUS_EDIT: {
      const { packagingStatus } = action.payload;
      return {
        ...state,
        cargoType: {
          ...state.cargoType,
          manualInput: {
            ...state.cargoType.manualInput,
            packagingStatus,
          },
        },
      };
    }

    case types.FULL_MANUAL_INPUT_EDIT: {
      const { manualInput } = action.payload;

      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          isManualInput: true,
          manualInput,
        },
      };
      return cascadeForRecommendedTruck(newState, null);
    }

    case types.FULL_MANUAL_INPUT_RESET: {
      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          isManualInput: false,
          manualInput: {
            ...defaultManualInput,
          },
        },
      };
      return cascadeForRecommendedTruck(newState, null);
    }

    case types.SLIDER_VOLUME_UPDATE: {
      const { volume, cargoType } = action.payload;

      const updateSliderVolumeValues = () => {
        if (cargoType === CARGO_TYPE.LCL) {
          return {
            ...state.cargoType.sliderValues,
            LCL: {
              ...state.cargoType.sliderValues.LCL,
              totalVolume: volume,
            },
          };
        }

        if (cargoType === CARGO_TYPE.AIR) {
          return {
            ...state.cargoType.sliderValues,
            AIR: {
              ...state.cargoType.sliderValues.AIR,
              totalVolume: volume,
            },
          };
        }

        return { ...state.cargoType.sliderValues };
      };

      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          sliderValues: updateSliderVolumeValues(),
        },
      };

      return cascadeForRecommendedTruck(newState, cargoType);
    }

    case types.SLIDER_WEIGHT_UPDATE: {
      const { weight, cargoType } = action.payload;

      const updateSliderWeightValues = () => {
        if (cargoType === CARGO_TYPE.LCL) {
          return {
            ...state.cargoType.sliderValues,
            LCL: {
              ...state.cargoType.sliderValues.LCL,
              totalWeight: weight,
            },
          };
        }

        if (cargoType === CARGO_TYPE.AIR) {
          return {
            ...state.cargoType.sliderValues,
            AIR: {
              ...state.cargoType.sliderValues.AIR,
              totalWeight: weight,
            },
          };
        }

        return { ...state.cargoType.sliderValues };
      };

      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          sliderValues: updateSliderWeightValues(),
        },
      };

      return cascadeForRecommendedTruck(newState, cargoType);
    }

    case types.CARGO_DETAILS_SIMULATION_DISPLAY: {
      const { cargoType, isOpen } = action.payload;

      if (!isOpen) {
        // Reset Product and packagingStatus
        return {
          ...state,
          cargoType: {
            ...state.cargoType,
            [cargoType]: [{ ...baseProduct }],
            isCargoDetailsSimulationOpen: {
              ...state.cargoType.isCargoDetailsSimulationOpen,
              [cargoType]: false,
            },
            isManualInput: false,
            manualInput: {
              ...defaultManualInput,
            },
          },
        };
      }

      const newState = {
        ...state,
        cargoType: {
          ...state.cargoType,
          isCargoDetailsSimulationOpen: {
            ...state.cargoType.isCargoDetailsSimulationOpen,
            [cargoType]: true,
          },
        },
      };

      return cascadeForRecommendedTruck(newState, cargoType);
    }

    case types.INITIAL_STATE_RESET: {
      return {
        ...state,
        ...initialSimulation,
      };
    }

    case types.SIMULATION_MODE_EDIT: {
      const { simulationMode } = action.payload;
      return {
        ...state,
        simulationMode,
      };
    }

    case types.SHIPMENT_ID_EDIT: {
      const { shipmentId } = action.payload;
      return {
        ...state,
        shipmentId,
      };
    }

    case types.SIMULATION_MISSING_VALUES: {
      const { payload } = action;
      return {
        ...state,
        missingValues: {
          ...state.missingValues,
          ...payload,
        },
      };
    }

    case types.SIMULATION_SET: {
      const { payload } = action;

      // Make sure local storage route is overridden
      const localStorageRoute = getLocalStorageObject(
        CONSTANTS.STORAGE_ROUTE, CONSTANTS.STORAGE_DEFAULTS.STORAGE_ROUTE,
      );
      localStorageRoute[payload.tradeDirection].POD = {
        id: payload.podId,
      };
      localStorageRoute[payload.tradeDirection].POL = {
        id: payload.polId,
      };
      localStorage.setItem(CONSTANTS.STORAGE_ROUTE, JSON.stringify(localStorageRoute));

      return parseSimulationVars(payload, state);
    }

    default:
      return state;
  }
};

export default simulation;
