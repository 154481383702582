import { AnyAction } from '@reduxjs/toolkit';

import { addPlace, editPlace } from './actions';
import * as types from './types';
import {
  tradeDirectionCascade,
  transportationModeCascade,
  cargoTypeCascade,
  isReeferSelected,
  cascadeOnPortsAndPlaces,
  cascadeForAdditionalRequest,
  getInitialState,
} from '../../utils/helpers/quoteRequestFormHelpers';

const INITIAL_STATE = getInitialState();

const quoteRequestForm = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case types.TRADE_DIRECTION_EDIT: {
      if (action.cascade) {
        // We wanna cascade the updates here
        const newState = {
          // If ever we wanna persist some stuff
          ...state,
          // things that should cascade
          ...tradeDirectionCascade(action.payload),
          tradeDirection: action.payload,
        };
        // The ports and places conditions needs to cascaded only once
        // the TD, Incoterms and DrayageRequest have been cascaded
        return {
          ...newState,
          ...cascadeOnPortsAndPlaces(newState),
        };
      }
      // no cascade
      return {
        ...state,
        tradeDirection: action.payload,
      };
    }

    case types.TRANSPORTATION_MODE_EDIT: {
      if (action.cascade) {
        return {
          ...state,
          // things that should cascade
          ...transportationModeCascade(action.payload, state),
          transportationMode: action.payload,
        };
      }
      // no cascade
      return {
        ...state,
        transportationMode: action.payload,
      };
    }

    case types.INCOTERM_EDIT: {
      if (action.cascade) {
        const newState = {
          ...state,
          incoterm: action.payload,
        };
        // The ports and places conditions needs to cascaded only once
        // the TD, Incoterms and DrayageRequest have been cascaded
        return {
          ...newState,
          ...cascadeOnPortsAndPlaces(newState),
        };
      }
      // no cascade
      return {
        ...state,
        incoterm: action.payload,
      };
    }

    case types.DRAYAGE_TRUCKING_REQUESTED: {
      if (action.cascade) {
        const newState = {
          ...state,
          drayageTruckingRequested: action.payload,
        };
        // The ports and places conditions needs to cascaded only once
        // the TD, Incoterms and DrayageRequest have been cascaded
        return {
          ...newState,
          ...cascadeOnPortsAndPlaces(newState),
        };
      }
      // no cascade
      return {
        ...state,
        drayageTruckingRequested: action.payload,
      };
    }

    case types.EDIT_POL_ID: {
      return {
        ...state,
        polId: action.payload || null,
      };
    }

    case types.EDIT_POD_ID: {
      return {
        ...state,
        podId: action.payload || null,
      };
    }

    case types.EDIT_PLACE: {
      const { placeId, index, placeType } = action.payload as ReturnType<typeof editPlace>['payload'];
      // TODO: tsrf improve after reducer state is typed
      const newPlaceArray = [...state?.requestDetails?.[placeType]] as (string | null)[];
      if (newPlaceArray) newPlaceArray[index] = placeId || null;
      return {
        ...state,
        requestDetails: {
          ...state?.requestDetails,
          [placeType]: newPlaceArray,
        },
      };
    }

    case types.ADD_PLACE: {
      const placeType = action.payload as ReturnType<typeof addPlace>['payload'];
      const newPlaceArray = [...state?.requestDetails?.[placeType]];
      newPlaceArray.push(null);
      return {
        ...state,
        requestDetails: {
          ...state?.requestDetails,
          [action.payload]: newPlaceArray,
        },
      };
    }

    case types.SET_PLACES: {
      const { places, placeType } = action.payload;
      const newPlacesArray = [...places];
      if (newPlacesArray.length === 0) newPlacesArray.push(null);
      return {
        ...state,
        requestDetails: {
          ...state?.requestDetails,
          [placeType]: places,
        },
      };
    }

    case types.CARGO_TYPE_EDIT: {
      if (action.cascade) {
        return {
          ...state,
          ...cargoTypeCascade(action.payload, state),
          cargoType: action.payload,
        };
      }

      return {
        ...state,
        cargoType: action.payload,
      };
    }

    case types.CARGO_TITLE_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          cargoTitle: action.payload,
        },
      };
    }

    case types.CONTAINER_TYPES_EDIT: {
      return {
        ...state,
        // Check if the given containers includes REEFER container
        requestDetails: isReeferSelected(action.payload)
          ? {
            ...state.requestDetails,
            containerTypes: action.payload,
          } : {
            // If No REEFER make reeferContainerInfo null
            ...state.requestDetails,
            reeferContainerInfo: null,
            containerTypes: action.payload,
          },
      };
    }

    case types.REEFER_CONTAINER_INFO_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          reeferContainerInfo: action.payload,
        },
      };
    }

    case types.DRAYAGE_TYPES_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          drayageTypes: action.payload,
        },
      };
    }

    case types.CONTAINS_DANGEROUS_GOODS_EDIT: {
      return {
        ...state,
        // Check if containsDangerousGoods is true or false
        requestDetails: action.payload
          ? {
            ...state.requestDetails,
            containsDangerousGoods: action.payload,
          }
          : {
            ...state.requestDetails,
            // If false, reset specialCargoDetails
            specialCargoDetails: null,
            containsDangerousGoods: action.payload,
          },
      };
    }

    case types.IS_LONG_CARGO_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          isLongCargo: action.payload,
        },
      };
    }

    case types.IS_NON_STACKABLE_CARGO_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          isNonStackableCargo: action.payload,
        },
      };
    }

    case types.SPECIAL_CARGO_DETAILS_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          specialCargoDetails: action.payload,
        },
      };
    }

    case types.SPECIAL_TRUCKING_REQUESTED_EDIT: {
      return {
        ...state,
        // Check if specialTruckingRequest is true or false
        requestDetails: action.payload
          ? {
            ...state.requestDetails,
            specialTruckingRequested: action.payload,
          } : {
            ...state.requestDetails,
            // If specialTruckingRequest false, reset specialTruckRequestDetails
            specialTruckRequestDetails: null,
            specialTruckingRequested: action.payload,
          },
      };
    }

    case types.SPECIAL_TRUCK_REQUEST_DETAILS_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          specialTruckRequestDetails: action.payload,
        },
      };
    }

    case types.INSURANCE_HANDLING_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          insuranceHandling: action.payload,
        },
      };
    }

    case types.CUSTOMS_HANDLING_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          customsHandling: action.payload,
        },
      };
    }

    case types.REMARKS_EDIT: {
      return {
        ...state,
        requestDetails: {
          ...state.requestDetails,
          remarks: action.payload,
        },
      };
    }

    case types.SET_ADDITIONAL_REQUEST: {
      return {
        ...state,
        additionalRequest: action.payload,
      };
    }

    case types.RESET_FORM: {
      return {
        ...INITIAL_STATE,
      };
    }

    case types.SET_STATE: {
      const newState = {
        ...state,
        ...action.payload,
        requestDetails: {
          ...state.requestDetails,
          ...action.payload?.requestDetails,
        },
      };

      if (action.cascade) {
        const cascadedState = {
          ...newState,
          ...cascadeOnPortsAndPlaces(newState, false),
        };
        return {
          ...cascadedState,
          ...cascadeForAdditionalRequest(cascadedState),
        };
      }

      return newState;
    }

    default:
      return state;
  }
};

export default quoteRequestForm;
