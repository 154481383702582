import { datadogRum } from '@datadog/browser-rum';

export const setUpDatadog = () => {
  if (['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
    datadogRum.init({
      applicationId: 'a042aa2b-8333-4e36-8929-b8af311f405b',
      clientToken: 'pub32687bbecc95f08746ae17f6f8abdd98',
      site: 'datadoghq.com',
      service: 'front-office',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_COMMIT_HASH,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  }
};

export const setUserDatadog = (id) => {
  if (['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
    datadogRum.setUser({
      id,
    });
  }
};
