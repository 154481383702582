import { ASSIGNABLE_ENTITIES } from '../constants/assignees';
import {
  CARGO_TYPES,
  CUSTOMS_HANDLING,
  INSURANCE_HANDLING,
  SCHEDULE_CALENDAR_DATE_TYPES,
  TOTAL_CALCULATION_ERROR,
  TRANSPORTATION_MODES,
} from '../constants/simulations';

/**
 * This helpers prepares the variables for
 * queries and mutations related to simulation
 * Back-End logic is in progress and
 * variables naming will continue to change
 * Here we can easily identify the variables to send to BE
 * while also checking the Business logic
 */

const validAddressList = (addressList, transportType) => addressList.filter(
  (address) => {
    if (transportType === 'drayage') {
      return !!address?.addressId && !!address?.drayageChassisType && !!address?.truckCount;
    }
    return !!address?.addressId && !!address?.truckType && !!address?.truckCount;
  },
);

const getTransportationRequest = (simulationData, transportType, location) => {
  if ((simulationData?.places?.[location]?.[transportType]?.reservation)
        && (simulationData?.places?.[location]?.[transportType]?.places.length > 0)) {
    const arr = simulationData?.places?.[location]?.[transportType]?.places.filter(
      ({ address }) => address !== null,
    ).map(
      ({ address, type, quantity }) => {
        const request = {
          addressId: address,
          truckCount: quantity,
        };
        if (transportType === 'drayage') {
          request.drayageChassisType = type;
        } else {
          request.truckType = type;
        }
        return request;
      },
    );
    return arr;
  }
  return [];
};

export const sanitize = (obj) => {
  if (!obj) {
    return null;
  }
  const { __typename, ...clone } = obj;
  return clone;
};

/**
 * Format simulation input based on simulation data that is obtainable from redux
 * @param {object} simulationData
 */
export default function quoteSimulationVars(simulationData) {
  const { tradeDirection } = simulationData;
  const cargoType = simulationData.cargoType.activeCargoType;

  const validArgs = {
    tradeDirection,
    polId: simulationData[tradeDirection].POL?.id,
    podId: simulationData[tradeDirection].POD?.id,
    cargoType,
    incoterm: simulationData[tradeDirection].incoterm,
  };

  if (cargoType === CARGO_TYPES.fcl) {
    // filter out any containers that have an invalid container type
    validArgs.containerRequests = simulationData.cargoType[cargoType].reduce((acc, container) => {
      if (container?.containerType) {
        acc.push(container);
      }
      return acc;
    }, []);
  } else if (simulationData?.cargoType?.[cargoType]) {
    const isQuickMode = !simulationData.cargoType.isCargoDetailsSimulationOpen[cargoType];

    // If Cargo Quick Simulation
    if (isQuickMode) {
      validArgs.cargoRequest = {
        ...validArgs.cargoRequest,
        manualInput: {
          ...simulationData.cargoType.manualInput,
          totalVolume: simulationData.cargoType.sliderValues[cargoType].totalVolume || 0,
          totalWeight: simulationData.cargoType.sliderValues[cargoType].totalWeight || 0,
        },
      };
    } else {
      // If Cargo Detailed Simulation then add products (if any) to the cargo request
      // Cargo type is either LCL or AIR
      const products = simulationData.cargoType[cargoType];
      // Filter out product with quantity null(new product without update) and 0
      const productWithQuantity = products.filter(({ quantity }) => (!!quantity));

      if (productWithQuantity.length) {
        validArgs.cargoRequest = {
          products: productWithQuantity.map((product) => (
            {
              productId: product?.productId,
              name: product?.name,
              height: product?.height || 0,
              width: product?.width || 0,
              length: product?.length || 0,
              weight: product?.weight || 0,
              packagingType: product?.packagingType,
              quantity: product?.quantity,
            }
          )),
        };
      }

      // If Cargo Detailed Simulation AND manual input is turned on, send it
      // along with products (if any exist)
      if (simulationData.cargoType.isManualInput) {
        validArgs.cargoRequest = {
          ...validArgs.cargoRequest,
          manualInput: simulationData.cargoType.manualInput,
        };
      }
    }
  }

  const transportType = cargoType === CARGO_TYPES.fcl
    ? 'drayage' : 'trucking';

  // Domestic places
  const domesticArray = getTransportationRequest(simulationData, transportType, 'domestic');
  if (validAddressList(domesticArray, transportType).length > 0) {
    if (transportType === 'drayage') {
      validArgs.drayageRequestsDomestic = domesticArray;
    } else {
      validArgs.truckingRequestsDomestic = domesticArray;
    }
  }

  // Overseas places
  const overseasArray = getTransportationRequest(simulationData, transportType, 'overseas');
  if (validAddressList(overseasArray, transportType).length > 0) {
    if (transportType === 'drayage') {
      validArgs.drayageRequestsOverseas = overseasArray;
    } else {
      validArgs.truckingRequestsOverseas = overseasArray;
    }
  }

  if (simulationData.additionalRequests?.customs) {
    validArgs.customsHandling = CUSTOMS_HANDLING.real_time;
  }

  if (simulationData.additionalRequests?.insurance) {
    validArgs.insuranceHandling = INSURANCE_HANDLING.consultation_required;
  }

  return validArgs;
}

/**
 * Reversed function of quoteSimulationVars
 * Format redux data based on simulation input
 * @param {object} simulationInputs
 */
export function parseSimulationVars(simulationInputs, state) {
  const defaultPlace = {
    address: null,
    type: null,
    quantity: 1,
  };

  const stateVars = state || {};
  const transportationMode = simulationInputs.cargoType === TRANSPORTATION_MODES.AIR
    ? TRANSPORTATION_MODES.AIR : TRANSPORTATION_MODES.OCEAN;
  const manualInput = sanitize(simulationInputs.cargoRequest.manualInput);
  const isManualInput = Object.keys(manualInput).some((inputKey) => manualInput[inputKey] !== null);
  const savedManualInput = isManualInput ? manualInput : {};
  const transportType = simulationInputs.cargoType === CARGO_TYPES.fcl
    ? 'drayage' : 'trucking';
  const requestsDomestic = simulationInputs[`${transportType}RequestsDomestic`];
  const requestsOverseas = simulationInputs[`${transportType}RequestsOverseas`];

  const cargoTypeKey = transportType === 'drayage'
    ? 'drayageChassisType' : 'truckType';

  const cargoRequestsDomestic = {
    [transportType]: {
      reservation: !!requestsDomestic?.length,
      places: requestsDomestic?.length
        ? requestsDomestic.map((cargo) => ({
          address: cargo.addressId,
          type: cargo[cargoTypeKey],
          quantity: cargo.truckCount,
        }))
        : [{ ...defaultPlace }],
    },
  };
  const cargoRequestsOverseas = {
    [transportType]: {
      reservation: !!requestsOverseas?.length,
      places: requestsOverseas?.length
        ? requestsOverseas.map((cargo) => ({
          address: cargo.addressId,
          type: cargo[cargoTypeKey],
          quantity: cargo.truckCount,
        }))
        : [{ ...defaultPlace }],
    },
  };

  const isCargoDetailsSimulationOpen = {
    ...stateVars.cargoType.isCargoDetailsSimulationOpen,
  };
  if (simulationInputs.cargoType !== 'FCL') {
    isCargoDetailsSimulationOpen[
      simulationInputs.cargoType
    ] = !!simulationInputs.cargoRequest.products.length || isManualInput;
  }

  const cargoInputs = simulationInputs.cargoType === 'FCL'
    ? simulationInputs.containerRequests.map((container) => sanitize(container))
    : simulationInputs.cargoRequest.products.map((product) => sanitize(product));

  const variables = {
    ...stateVars,
    tradeDirection: simulationInputs.tradeDirection,
    [simulationInputs.tradeDirection]: {
      transportationMode,
      incoterm: simulationInputs.incoterm,
      POD: {
        id: simulationInputs.podId,
      },
      POL: {
        id: simulationInputs.polId,
      },
    },
    cargoType: {
      ...stateVars.cargoType,
      activeCargoType: simulationInputs.cargoType,
      isManualInput,
      isCargoDetailsSimulationOpen,
      [simulationInputs.cargoType]: cargoInputs,
      manualInput: {
        ...stateVars.cargoType.manualInput,
        ...savedManualInput,
      },
      sliderValues: {
        ...stateVars.cargoType.sliderValues,
        [simulationInputs.cargoType]: {
          totalVolume: simulationInputs.cargoRequest.manualInput.totalVolume,
          totalWeight: simulationInputs.cargoRequest.manualInput.totalWeight,
        },
      },
    },
    places: {
      domestic: {
        ...stateVars.places.domestic,
        ...cargoRequestsDomestic,
      },
      overseas: {
        ...stateVars.places.overseas,
        ...cargoRequestsOverseas,
      },
    },
    additionalRequests: {
      customs: !!simulationInputs.customsHandling,
      insurance: !!simulationInputs.insuranceHandling,
    },
    vehicleManuallyChanged: true,
    recommendedTruck: null,
  };

  return variables;
}

/**
 * Schedule values are part of the simulation
 * SimulationData is a simulation object of the Redux Store
 * at the exact moment a simulation was selected to convert
 * Since values could change in the store
 * I sending them to guard the synchronicity of the event
 */
export const convertShipmentArgs = (
  pricingSectionIds,
  pod,
  pol,
  simulationData,
  schedule,
  assignee,
) => {
  const simulationArgs = quoteSimulationVars(simulationData);

  simulationArgs.polId = pol?.id;
  simulationArgs.podId = pod?.id;
  const voyageSchedule = {};
  let deliveryDate = null;

  if (schedule.method === 'calendar') {
    switch (schedule.calendar.dateType) {
      case SCHEDULE_CALENDAR_DATE_TYPES.ETD:
        voyageSchedule.manualRequest = { etd: schedule.calendar.date };
        break;
      case SCHEDULE_CALENDAR_DATE_TYPES.ETA:
        voyageSchedule.manualRequest = { eta: schedule.calendar.date };
        break;
      case SCHEDULE_CALENDAR_DATE_TYPES.CUTOFFDATE:
        voyageSchedule.manualRequest = { cutOffDate: schedule.calendar.date };
        break;
      case SCHEDULE_CALENDAR_DATE_TYPES.DELIVERY_DATE:
        deliveryDate = { deliveryDate: schedule.calendar.date };
        break;
      default:
        break;
    }
  } else {
    voyageSchedule.id = schedule.vesselId;
  }

  const assignmentArgs = {};

  // eslint-disable-next-line no-underscore-dangle
  if (assignee?.id && assignee?.__typename && ASSIGNABLE_ENTITIES[assignee.__typename]) {
    assignmentArgs.assignmentDetails = {
      assigneeId: assignee.id,
      // eslint-disable-next-line no-underscore-dangle
      assigneeType: assignee.__typename,
    };
  }

  return {
    ...deliveryDate,
    voyageSchedule,
    simulationArgs,
    selectedQuote: { pricingSectionIds },
    ...assignmentArgs,
  };
};

/**
 *
 * @param {number} demValue e.g. 14
 * @param {number} detValue e.g. 7
 * @returns {string} e.g. 14dem + 7det
 */
export const getFreeTimeString = (demValue, detValue) => {
  if (!demValue && !detValue) return null;

  return `${demValue ? `${demValue} dem` : ''}${
    demValue && detValue ? ' + ' : ''
  }${detValue ? `${detValue} det` : ''}`;
};

/**
 *
 * @param {array} containersData
 * @param {i18n} t
 * @returns {string} e.g. 20GP x 1, 40GP x 1
 */
export const getContainerString = (containersData, t) => containersData.map(
  ({ containerType, containerCount }) => {
    // sometimes we have { containerType: null, containerCount: 1 } for example
    // when we have no carrier quotes
    if (containerType) {
      const shortName = t(`simulation:containers.shortNames.${containerType}`);
      return `${shortName} x ${containerCount}`;
    }

    return '';
  },
).join(', ');

/**
 * Adding small error to given numerical value to mitigate rounding issues
 * @param {number} value
 */
const addErrorToValue = (value) => value - TOTAL_CALCULATION_ERROR;

/**
 * R/T: The greater value of totalVolume and totalWeight/1000
 * @param {number} totalVolume
 * @param {number} totalWeight
 * @returns {number} e.g. 1234
 */
export const calculateRT = (totalVolume, totalWeight) => {
  const totalVolumeWithError = addErrorToValue(totalVolume);

  const oneThousandthOfWeight = totalWeight / 1000;
  const oneThousandthOfWeightWithError = addErrorToValue(oneThousandthOfWeight);

  const ceiledWeight = Math.ceil(oneThousandthOfWeightWithError * 1000) / 1000;
  const ceiledVolume = Math.ceil(totalVolumeWithError * 1000) / 1000;

  const rt = ceiledVolume > ceiledWeight ? ceiledVolume : ceiledWeight;

  if (rt > 0 && rt < 1) return 1;
  return rt;
};

/**
 * C/W: The greater value of totalVolume(cm)/6000 and totalWeight
 * @param {number} totalVolume
 * @param {number} totalWeight
 * @returns {number} e.g. 1234
 */
export const calculateCW = (totalVolume, totalWeight) => {
  const totalVolumeCentimeter = totalVolume * 1000000;

  const totalVolumeWeight = totalVolumeCentimeter / 6000;
  const totalVolumeWeightWithError = addErrorToValue(totalVolumeWeight);

  const ceiledTotalVolumeWeight = Math.ceil(totalVolumeWeightWithError * 1000) / 1000;

  const totalWeightWithError = addErrorToValue(totalWeight * 1000);

  const ceiledWeight = Math.ceil(totalWeightWithError) / 1000;
  const cw = ceiledTotalVolumeWeight > ceiledWeight ? ceiledTotalVolumeWeight : ceiledWeight;

  return cw > 0.5 ? cw : 0.5;
};
