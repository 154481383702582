import { ADDRESS_TYPES } from '../../utils/constants/places';
import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initialPlaces = {
  addressType: ADDRESS_TYPES.customer_team_related,
};

const places = (state = initialPlaces, action) => {
  switch (action.type) {
    case types.ADDRESS_TYPE_EDIT: {
      const { addressType } = action.payload;

      return {
        ...state,
        addressType,
      };
    }

    default:
      return state;
  }
};

export default places;
